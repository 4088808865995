* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  body {
    /* background: #000210; */
    color: #fff;
    height: 100;
  }
  
  img {
    width: 100%;
    height: 100%;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
    height: 3px;
    border-top-left-radius: 60px !important;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0000 linear-gradient(268deg, #1b9ffe, #3b3ad6) 0 0 no-repeat
      padding-box;
    border-radius: 10px;
  }
  
  a {
    text-decoration: none;
  }
  
  a {
    color: #1b9ffe;
  }
  
  .fs-60 {
    font-size: 60px;
  }
  
  .fs-toggle {
    font-size: 1px !important;
    color: transparent;
  }
  
  .fs-14 {
    font-size: 14px !important;
  }
  
  .fs-12 {
    font-size: 12px !important;
  }
  .fs-16 {
    font-size: 16px !important;
  }
  .fs-18 {
    font-size: 18px !important;
  }
  
  .fs-20 {
    font-size: 20px !important;
  }
  .fs-22 {
    font-size: 22px !important;
  }
  
  .fs-24 {
    font-size: 24px !important;
  }
  
  .fs-30 {
    font-size: 30px !important;
  }
  
  .fs-28 {
    font-size: 28px;
  }
  
  .fw-500 {
    font-weight: 500 !important;
  }
  
  .fw-600 {
    font-weight: 600 !important;
  }
  
  .navbar {
    padding: 0;
  }
  
  .bg-light {
    background: #ffffff !important;
    /* fallback for old browsers */
  }
  
  .navbar-brand img {
    width: 160px;
    height: auto;
  }
  .navbar-section {
    background: rgba(27, 159, 254, 0.1);
  
    color: #fff;
  }
  
  .switch-tabs-btn-group {
    background: rgba(27, 159, 254, 0.1);
    border-radius: 25px;
    margin-bottom: 40px;
    color: #fff !important;
  }
  .switch-tabs-btn-group button{
    color: #fff !important;
  }
  .nav-icons {
    font-size: 16px;
  }
  
  .switch-banner-section {
    /* border: 1px solid #1b9ffe; */
    border-radius: 15px;
    backdrop-filter: blur(20px);
    background: rgba(27, 159, 254, 0.1);
    border: 1px solid #00e5ff21;
    margin: auto !important;
  }
  
  .switch-banner-amount-section {
    margin-left: 3%;
    margin-right: 3%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    border: 1px Solid #3989f92d;
    border-radius: 12px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .switch-banner-section-text-2 {
    font-size: 14px;
  }
  .switch-banner-section-text-3 {
    font-size: 17px;
    color: #f7f7f7;
    font-weight: 500;
  }
  
  .form-control-amount,
  .form-control-amount:focus {
    display: block;
    width: 100%;
    padding: 0.375rem 0.15rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #ffffff00;
    background-clip: padding-box;
    /* border: 1px solid #ced4da00; */
    border: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none !important;
  }
  .form-control-amount2,
  .form-control-amount2:focus {
    display: block;
    width: 100%;
    padding: 0.375rem 0.15rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #ffffff00;
    background-clip: padding-box;
    /* border: 1px solid #ced4da00; */
    border: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none !important;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .btn-max {
    color: rgba(255, 255, 255, 0.815) !important;
    background: #0000 linear-gradient(268deg, #3989f952, #3a3ad660) 0 0 no-repeat
      padding-box;
    border-color: #0d6efd;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.275rem 0.75rem;
    font-size: 14px;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .switch-banner-amount-section ::placeholder {
    font-size: 14px;
    font-family: monospace;
  }
  
  .greater-icons {
    font-size: 11px;
    margin-bottom: 2px;
    margin-left: 1%;
    margin-right: 1%;
  }
  .bottom-switch-select-section {
    border-top: 1px solid #3a3ad64d;
  }
  
  .bottom-switch-select-section .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background: #0000 linear-gradient(268deg, #3989f9da, #3a3ad6c9) 0 0 no-repeat
      padding-box;
  }
  
  .btn-close {
    box-shadow: none !important;
    border: 2px solid #fff;
    background-color: #fff;
  }
  .copy-icons {
    cursor: pointer;
  }
  .modal-content {
    background: rgba( 0, 2, 16, 0.25 );
    backdrop-filter: blur( 444px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid #ffffff0c;
  }
  .modal-btc-logo {
    width: 100px;
  }
  .modal-info-text-1 {
    font-size: 14px;
    padding: 0rem;
    margin: 0rem !important;
    padding-bottom: 6px;
  }
  .modal-info-text-2 {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0rem;
    margin: 0rem !important;
    padding-bottom: 4px;
  }
  .modal-info-text-3 {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 0rem;
    margin: 0rem !important;
    padding-bottom: 4px;
  }
  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 0rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .modal-body {
    padding-top: 0rem !important;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .referral-button-copy{
  
    padding: 3px 4px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 25px;
    border: none;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgb(255, 255, 255);
    border: 1px solid #00e5ff10;
    color: #1b9ffe !important;
    overflow: hidden;
    font-size: 12px !important;
    font-weight: 300 !important;
    transition: all 0.3s ease-in-out;
    outline: none !important;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  /* ============ desktop view ============ */
  @media all and (min-width: 992px) {
  
    .copy-referral-text{
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 14px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      letter-spacing: 1px;
    }
    .referal-copy-inner-section{
      padding: 10px 30px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 50px;
      border: none;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background: rgba(27, 159, 254, 0.1);
      border: 1px solid #00e5ff10;
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 14px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      letter-spacing: 1px;
    }
  
    .ReferalBoard-text-1{
      font-weight: bold;
      font-size: 1.5rem;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff !important;
      display: inline-block;
      position: relative;
      opacity: 0.75;
    }
    .leaderboard-button-copy{
      margin-left: 8px;
      padding: 10px 30px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 25px;
      border: none;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background: rgb(255, 255, 255);
      border: 1px solid #00e5ff10;
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 14px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .trans-back-button{
      backdrop-filter: blur(20px);
      background: rgba(27,159,254,.1);
      border: 1px solid #00e5ff21;
      border-radius: 50%;
      font-size: 20px;
      padding: 8px 12px;
      padding-bottom: 12px;
      width: fit-content;
      position: relative;
      bottom: -42px;
      cursor: pointer;
      color: #FFF;
    }
    .ml-logo {
      margin-left: -11px !important;
    }
    .amount-select-image {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .amount-select-from {
      font-size: 14px;
    }
  
    .amount-select-name {
      font-size: 17px;
      color: #fcfcfc;
      font-weight: 500;
    }
    .bottom-switch-select-section-text-1 {
      font-size: 13px;
      font-weight: 400;
    }
    .bottom-switch-select-section .nav-link {
      display: block;
      padding: 0.6rem 0.6rem;
      color: #0d6efd;
      text-decoration: none;
      border: 1px solid #3989f973;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
    }
    .mobile-transaction {
      display: none !important;
    }
    .centericon-swap {
      font-size: 60px;
      border-radius: 50%;
      color: #fff;
      background: #0000 linear-gradient(268deg, #1b9ffe, #3b3ad6) 0 0 no-repeat
        padding-box;
      padding: 10px;
    }
    .connect-wallet-button,
    .connect-wallet-button:link,
    .connect-wallet-button:visited {
      padding: 10px 20px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 25px;
      border: none;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background: rgba(27, 159, 254, 0.1);
      border: 1px solid #00e5ff10;
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 14px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  
    .connect-wallet-button:hover,
    .connect-wallet-button:active {
      transform: translateY(-2px);
      box-shadow: 0 6px 12px 0 rgba(255, 255, 255, 0.308);
    }
  
    .connect-wallet-button-2,
    .connect-wallet-button-2:link,
    .connect-wallet-button-2:visited {
      padding: 10px 20px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 25px;
      border: none;
      background: #fff !important;
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 14px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  
    .connect-wallet-button-2:hover,
    .connect-wallet-button-2:active {
      transform: translateY(-2px);
      box-shadow: 0 6px 12px 0 rgba(255, 255, 255, 0.308);
    }
    .connect-trans-button {
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff !important;
      padding: 20px 0px;
      display: inline-block;
      position: relative;
      opacity: 0.75;
    }
  
    .connect-trans-button:hover {
      opacity: 1;
    }
  
    .connect-trans-button::before {
      transition: 300ms;
      height: 2px;
      content: "";
      position: absolute;
      background-color: #fff !important;
    }
  
    .nav-link-ltr::before {
      width: 0%;
      bottom: 10px;
    }
  
    .nav-link-ltr:hover::before {
      width: 90%;
    }
    .switch-banner-section-text-1 {
      font-family: sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.5;
      padding: 10px 5% 10px;
      text-align: start;
    }
  
    .switch-tabs-section-bsc {
      background: #0000 linear-gradient(268deg, #1b9ffe, #3b3ad6) 0 0 no-repeat
        padding-box;
      border-radius: 25px !important;
      padding: 0.8rem 2.4rem !important;
      color: #fff;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
      border-radius: 25px;
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
      box-shadow: none !important;
    }
    .switch-tabs-section-bsc:hover {
      color: #1b9ffe;
    }
    .switch-tabs-section-poly {
      /* background: #0000 linear-gradient(268deg,#1b9ffe,#3b3ad6) 0 0 no-repeat padding-box; */
      border-radius: 25px !important;
      padding: 0.8rem 2.4rem !important;
      /* color: #1b9ffe; */
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
      border-radius: 25px;
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
      box-shadow: none !important;
    }
    .switch-tabs-section-poly:hover {
      color: #1b9ffe;
    }
    .switch-tabs-section-eth {
      /* background: #0000 linear-gradient(268deg,#1b9ffe,#3b3ad6) 0 0 no-repeat padding-box; */
      border-radius: 25px !important;
      padding: 0.8rem 2.4rem !important;
      /* color: #1b9ffe; */
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
      border-radius: 25px;
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
      box-shadow: none !important;
    }
    .switch-tabs-section-eth:hover {
      color: #1b9ffe;
    }
    .switch-banner-section-text-4 {
      cursor: pointer;
      margin-left: 10%;
      /* margin-bottom: 2px; */
      color: red;
      font-size: 25px;
      /* margin-bottom: -10%; */
    }
    .amount-select-section {
      margin-left: 10%;
    }
    .mobile-break {
      display: none;
    }
    .switch-banner-section-inner {
      margin-left: 2%;
      margin-right: 2%;
    }
    .amount-select-to {
      font-size: 14px;
      margin-right: 22%;
    }
    .bottom-switch-select-section-image {
      width: 30px;
      height: 30px;
      margin-bottom: 5px;
      margin-left: 1%;
      margin-right: 1%;
    }
    .bottom-switch-select-section-image-1 {
      width: 25px;
      height: 25px;
      margin-bottom: 5px;
      margin-left: 1%;
      margin-right: 1%;
    }
  
    .network-image {
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
      margin-right: 4px;
    }
  }
  
  /* ============ mobile view ============ */
  @media (max-width: 991px) {
    .copy-referral-text{
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 14px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      letter-spacing: 1px;
    }
    .referal-copy-inner-section{
      padding: 10px 30px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 50px;
      border: none;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background: rgba(27, 159, 254, 0.1);
      border: 1px solid #00e5ff10;
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 14px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      letter-spacing: 1px;
    }
  
    .ReferalBoard-text-1{
      font-weight: bold;
      font-size: 1.2rem;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff !important;
      display: inline-block;
      position: relative;
      opacity: 0.75;
    }
    .leaderboard-button-copy{
      margin-left: 8px;
      padding: 3px 20px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 25px;
      border: none;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background: rgb(255, 255, 255);
      border: 1px solid #00e5ff10;
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 12px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .trans-back-button{
      backdrop-filter: blur(20px);
      background: rgba(27,159,254,.1);
      border: 1px solid #00e5ff21;
      border-radius: 50%;
      font-size: 12px;
      padding: 4px 8px;
      padding-bottom: 8px;
      width: fit-content;
      cursor: pointer;
      color: #FFF;
    }
    .ml-logo {
      margin-left: -5px !important;
    }
    .amount-select-image {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .amount-select-from {
      font-size: 13px;
    }
  
    .amount-select-name {
      font-size: 15px;
      color: #f5f4f4;
      font-weight: 500;
    }
    .bottom-switch-select-section-text-1 {
      font-size: 10px;
      font-weight: 400;
    }
    .bottom-switch-select-section .nav-link {
      display: block;
      padding: 0.2rem 0.2rem;
      color: #0d6efd;
      text-decoration: none;
      border: 1px solid #3989f973;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
    }
    .network-image {
      width: 16px;
      height: 16px;
      margin-bottom: 2px;
      margin-right: 4px;
    }
    .desktop-transaction {
      display: none !important;
    }
    .centericon-swap {
      font-size: 30px;
      border-radius: 50%;
      color: #fff;
      background: #0000 linear-gradient(268deg, #1b9ffe, #3b3ad6) 0 0 no-repeat
        padding-box;
      padding: 4px;
    }
    .connect-wallet-button,
    .connect-wallet-button:link,
    .connect-wallet-button:visited {
      padding: 10px 10px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 25px;
      border: none;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background: rgba(27, 159, 254, 0.1);
      border: 1px solid #00e5ff10;
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 12px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 8px !important;
    }
  
    .connect-wallet-button:hover,
    .connect-wallet-button:active {
      transform: translateY(-2px);
      box-shadow: 0 6px 12px 0 rgba(255, 255, 255, 0.308);
    }
    .connect-wallet-button-2,
    .connect-wallet-button-2:link,
    .connect-wallet-button-2:visited {
      padding: 10px 10px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 25px;
      border: none;
      background: #fff !important;
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 12px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 8px !important;
    }
  
    .connect-wallet-button-2:hover,
    .connect-wallet-button-2:active {
      transform: translateY(-2px);
      box-shadow: 0 6px 12px 0 rgba(255, 255, 255, 0.308);
    }
    .connect-trans-button,
    .connect-trans-button:link,
    .connect-trans-button:visited {
      padding: 12px 5px 12px 12px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 25px;
      border: none;
      background: #fff !important;
      color: #1b9ffe !important;
      overflow: hidden;
      font-size: 12px !important;
      font-weight: 300 !important;
      transition: all 0.3s ease-in-out;
      outline: none !important;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 8px !important;
    }
  
    .connect-trans-button:hover,
    .connect-trans-button:active {
      transform: translateY(-2px);
      box-shadow: 0 6px 12px 0 rgba(255, 255, 255, 0.308);
    }
    .tabs-none {
      display: none;
    }
    .switch-banner-section-text-1 {
      font-family: sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5;
      padding: 10px 5% 0px;
      text-align: start;
    }
  
    .switch-tabs-section-bsc {
      background: #0000 linear-gradient(268deg, #1b9ffe, #3b3ad6) 0 0 no-repeat
        padding-box;
      border-radius: 25px !important;
      padding: 0.5rem 0.5rem !important;
      color: #fff;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
      border-radius: 25px;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      box-shadow: none !important;
    }
    .switch-tabs-section-bsc:hover {
      color: #1b9ffe;
    }
    .switch-tabs-section-poly {
      /* background: #0000 linear-gradient(268deg,#1b9ffe,#3b3ad6) 0 0 no-repeat padding-box; */
      border-radius: 25px !important;
      padding: 0.5rem 0.5rem !important;
      /* color: #1b9ffe; */
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
      border-radius: 25px;
      font-size: 10px;
      text-transform: uppercase;
      box-shadow: none !important;
    }
    .switch-tabs-section-poly:hover {
      color: #1b9ffe;
    }
    .switch-tabs-section-eth {
      /* background: #0000 linear-gradient(268deg,#1b9ffe,#3b3ad6) 0 0 no-repeat padding-box; */
      border-radius: 25px !important;
      padding: 0.5rem 0.5rem !important;
      /* color: #1b9ffe; */
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
      border-radius: 25px;
      font-size: 10px;
      text-transform: uppercase;
      box-shadow: none !important;
    }
    .switch-tabs-section-eth:hover {
      color: #1b9ffe;
    }
    .switch-banner-section-text-4 {
      cursor: pointer;
      /* margin-left: 5%;
        margin-bottom: 2px; */
      color: red;
      font-size: 18px;
    }
    .amount-select-to {
      font-size: 14px;
    }
    .bottom-switch-select-section-image {
      width: 15px;
      height: 15px;
      margin-bottom: 3px;
      margin-left: 1%;
      margin-right: 1%;
    }
    .bottom-switch-select-section-image-1 {
      width: 15px;
      height: 15px;
      margin-bottom: 5px;
      margin-left: 1%;
      margin-right: 1%;
    }
  }
  
  .button-container-1 {
    position: relative;
    width: 250px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    border: 1px solid #1b9ffe;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 20px;
    transition: 0.5s;
    letter-spacing: 1px;
    border-radius: 10px;
  }
  .button-container-1 button {
    width: 101%;
    height: 100%;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: bold;
    background: #1b9ffe;
    -webkit-mask: url("./assets/images/nature-sprite.png");
    mask: url("./assets/images/nature-sprite.png");
    -webkit-mask-size: 2300% 100%;
    mask-size: 2300% 100%;
    border: none !important;
    color: #fff;
    cursor: pointer;
    -webkit-animation: ani2 0.7s steps(22) forwards;
    animation: ani2 0.7s steps(22) forwards;
  }
  .button-container-1 button:hover {
    -webkit-animation: ani 0.7s steps(22) forwards;
    animation: ani 0.7s steps(22) forwards;
  }
  
  .mas {
    position: absolute;
    color: #FFF;
    text-align: center;
    width: 101%;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    position: absolute;
    font-size: 11px;
    margin-top: 17px;
    overflow: hidden;
    font-weight: bold;
  }
  
  @-webkit-keyframes ani {
    from {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
    to {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
  }
  @keyframes ani {
    from {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
    to {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
  }
  @-webkit-keyframes ani2 {
    from {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
    to {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
  }
  @keyframes ani2 {
    from {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
    to {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
  }
  
  .table th {
    padding: 12px 15px;
  
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .table{
    color: #FFF;
  }
  
  .table thead {
    background: rgba(27, 159, 254, 0.1);
    text-transform: uppercase;
  }
  
  .table tbody tr:hover {
    background: linear-gradient(268deg, rgb(57, 136, 249), rgb(59, 58, 214)) 0px
      0px no-repeat padding-box padding-box rgba(0, 0, 0, 0);
    color: #fff;
  }
  
  /*responsive*/
  
  @media (max-width: 500px) {
    .table thead {
      display: none;
    }
  
    .table,
    .table tbody,
    .table tr,
    .table td {
      display: block;
      width: 100%;
    }
    .table tr {
      margin-bottom: 15px;
    }
    .table td {
      padding-left: 30%;
      text-align: left;
      position: relative;
    }
    .table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  .truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
    margin-left: auto;
  }
  .truncate-text2 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
    margin-left: auto;
  }
  .copy-text-button {
    position: relative;
    top: -8px;
  }
  table {
    border-radius: 12px !important;
  }
  
  @media all and (min-width: 992px) {
    .table {
      width: 100%;
      border-collapse: collapse;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
      border-color: inherit;
      border-style: none;
      border-width: 0;
    }
  
    .table td {
      padding: 12px 15px;
      text-align: center;
      font-size: 16px;
    }
  }
  
  @media (max-width: 991px) {
    .table {
      width: 100%;
      border-collapse: collapse;
    }
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
      border-color: inherit;
      border-style: none;
      border-width: 0;
    }
    .table td {
      padding: 12px 15px;
  
      text-align: center;
      font-size: 16px;
      border: 1px solid #edeef2;
    }
    .table tr {
      margin-bottom: 50px;
    }
  }
  .form-select{
    background-color: transparent;
    color: #FFF;
    border: 0px;
    box-shadow: none !important;
  }
  
  .option-value{
    background: #041228;
    border: 0px !important;
  }
  
  .huwXap {
    color: #fff !important;
    background: transparent !important;
  
    border-bottom: 0px;
  }
  
  
  .marquee-container {
    width: 100%;
    height: 50px;
    vertical-align: middle;
    background-color: #000;
    font-size: 1.06rem;
    font-weight: 400;
    display: table-cell;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: sans-serif;
  }
  .overlay::before, .overlay::after {
    background: none !important;
    content: "";
    height: 100%;
    position: absolute;
    width: var(--gradient-width);
    z-index: 2;
  }
  